import axios from './axiosInit'

import sharedServices from './shared-services';
import store from '@/store'
const getEntities = async (userName) =>
{
    try
    {
        // store.dispatch('loader/show');
        return sharedServices.getToken('entity', userName).then(async entityRes =>
        {
            let payload = {
                requestInfo: {
                    channelKey: entityRes.data.results.channelKey,
                    identityKey: entityRes.data.results.identityKey,
                    requestDate: entityRes.data.results.requestDate,
                    requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                    channelId: store.get('requestKeys/channelId'),
                    userName: userName,
                    identitySeed: sharedServices.getIdSeed()
                },
                refNumber: store.get('requestKeys/ClientCode'),
                reference: 'U'
            };
            store.set('requestKeys/channelKey', entityRes.data.results.channelKey);
            store.set('requestKeys/identityKey', entityRes.data.results.identityKey);
            store.set('requestKeys/requestDate', entityRes.data.results.requestDate);
            const auth = {
                headers: {
                    Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
                }
            }

            const result = await axios.post('hpsbridge/se/Entities/list', payload, auth)
            // store.dispatch('loader/hide');
            return result
        })
    } catch (e)
    {
        // console.log(e)
    }
}

const getSummaryMR = async (userName, loyaltyAccountNumber) =>
{
    try
    {
        let payload = {
            // requestInfo: {
            //     channelKey: entityRes.data.results.channelKey,
            //     identityKey: entityRes.data.results.identityKey,
            //     requestDate: entityRes.data.results.requestDate,
            //     requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
            //     channelId: store.get('requestKeys/channelId'),
            //     userName: userName,
            //     identitySeed: sharedServices.getIdSeed()
            // },

            // requestInfo: {
            //     requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
            //     requestDate: store.get('requestKeys/requestDate'),
            //     identityKey: store.get('requestKeys/identityKey'),
            //     channelId: store.get('requestKeys/channelId'),
            //     channelKey: store.get('requestKeys/channelKey'),
            //     identitySeed: store.get('requestKeys/identitySeed'),
            //     userName: store.get('requestKeys/userName'),
            // },
            loyaltyAccountNumber: loyaltyAccountNumber
        };

        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
            }
        }

        // https://online.americanexpress.com.sa/HPSBridge/hpsbridge_staging/StarAESA_Staging/Star/GetSummary
        // https://online.americanexpress.com.sa/HPSBridge/hpsbridge_staging/StarAESA_Staging/
        // https://online.americanexpress.com.sa/HPSBridge/StarAESA/
        const result = await axios.post('HPSBridge/StarAESA/Star/GetSummary', payload, auth)
        // store.dispatch('loader/hide');
        return result
    } catch (e)
    {
        console.log('getSummaryMRgetSummaryMRgetSummaryMRgetSummaryMRgetSummaryMR', e)
    }
}


export default {
    getEntities,
    getSummaryMR
}
