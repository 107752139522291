<template>
    <v-card flat
            class="balance-card-background"
            style="min-height: 192px;">
        <v-card-text class="px-lg-5 px-md-2 px-sm-2 px-1">
            <v-row class="mx-1">
                <v-col cols="12"
                       lg="8"
                       md="12"
                       sm="12">
                    <p class="text-2xl font-weight-semibold pt-1 text-color-black"
                       style="font-size: 18px !important;margin-bottom: 0px;">
                        <span v-if="selectedLanguage == 'ar'">{{ cardDetails.cardNameAr }}</span>
                        <span v-if="selectedLanguage == 'en'">{{ cardDetails.cardName }}</span>
                    </p>
                    <div class="text-1xl font-weight-semibold"
                         style="font-size: 13px;">
                        <span>
                            {{ cardDetails.serialNo }}
                        </span>
                    </div>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="12"
                       sm="12">
                    <p class="text-2xl font-weight-semibold pt-1 text-color-black"
                       style="font-size: 12px !important;margin-bottom: 0px;">
                        {{ $t('Credit limit :') }}
                        <span v-if="selectedLanguage == 'en' && cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                            {{ $t(cardDetails.creditLimit.cur) }}
                        </span>
                        <span v-if="cardDetails.availableCredit.amt == 'No Pre-Set Limit'">
                            {{ $t(cardDetails.availableCredit.amt) }}
                        </span>
                        <span v-if="cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                            {{ cardDetails.creditLimit.amt | VMask(currencyMask) }}
                        </span>
                        <span v-if="selectedLanguage == 'ar' && cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                            {{ $t(cardDetails.creditLimit.cur) }}
                        </span>
                    </p>
                </v-col>
            </v-row>


            <v-row style="border-radius: 5px;"
                   class="mx-4 card-background align-center">
                <v-col lg="5"
                       md="12"
                       sm="12"
                       class="balance">

                    <div class="d-flex justify-center flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column">

                        <div class="arabic-divider">

                            <div class="text-black arabic-credit"
                                 style="font-size: 11px;">{{ $t('Current Balance') }}
                                <span>
                                    <v-tooltip color="primary"
                                               top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img v-bind="attrs"
                                                 v-on="on"
                                                 src="@/assets/images/icons/iota-circle.png"
                                                 alt=""
                                                 class="mx-1"
                                                 style="width: 10px; margin-bottom: -1px;">
                                        </template>
                                        <div style="max-width: 200px;padding: 6px;">
                                            <span>{{ $t(`CurrentBalanceInfo`) }}</span>
                                        </div>
                                    </v-tooltip>
                                </span>
                            </div>
                            <div class="text-color-black font-weight-semibold text-2xl"
                                 style="font-size: 15px !important; margin-top:-5px">
                                <span v-if="selectedLanguage == 'en'">
                                    {{ $t(cardDetails.currentBalance.cur) }}
                                </span>
                                {{ cardDetails.currentBalance.amt | VMask(currencyMask) }}
                                <span v-if="selectedLanguage == 'ar'">
                                    {{ $t(cardDetails.currentBalance.cur) }}
                                </span>
                                <span v-if="cardDetails.currentBalance.cr">CR</span>
                            </div>
                        </div>
                        <div class="d-flex justify-center arabic-divider mr-2 ml-5">
                            <v-divider class="my-1"
                                       :vertical="true"></v-divider>
                        </div>
                        <div class="mr-3 ml-4">
                            <div class="text-black arabic-credit"
                                 style="font-size: 11px;">{{ $t('Available Credit') }}
                                <span>
                                    <v-tooltip color="primary"
                                               top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img v-bind="attrs"
                                                 v-on="on"
                                                 src="@/assets/images/icons/iota-circle.png"
                                                 alt=""
                                                 class="mx-1"
                                                 style="width: 10px; margin-bottom: -1px;">
                                        </template>
                                        <div style="max-width: 200px;padding: 6px;">
                                            <span>{{ $t(`AvailableCreditInfo`) }}</span>
                                        </div>
                                    </v-tooltip>
                                </span>
                            </div>

                            <div class="text-color-black font-weight-semibold text-2xl"
                                 style="font-size: 15px !important; margin-top:-5px">
                                <span v-if="selectedLanguage == 'en' && cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                    {{ $t(cardDetails.availableCredit.cur) }}
                                </span>
                                <span v-if="cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                    {{ cardDetails.availableCredit.amt | VMask(currencyMask) }}
                                </span>
                                <span v-if="cardDetails.availableCredit.amt == 'No Pre-Set Limit'">
                                    {{ $t(cardDetails.availableCredit.amt) }}
                                </span>
                                <span v-if="selectedLanguage == 'ar' && cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                    {{ $t(cardDetails.availableCredit.cur) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12"
                       lg="5"
                       md="12"
                       sm="12"
                       class="balance">
                    <div class="d-flex flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column balance-space">
                        <div>
                            <span class="text-black ar-fontsize fontsize">{{ $t('Previous Balance') }}</span>
                            <span>
                                <v-tooltip color="primary"
                                           top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img v-bind="attrs"
                                             v-on="on"
                                             src="@/assets/images/icons/iota-circle.png"
                                             alt=""
                                             class="mx-1"
                                             style="width: 10px;">
                                    </template>
                                    <div style="max-width: 200px;padding: 6px;">
                                        <span>{{ $t(`PreviousBalanceInfo`) }}</span>
                                    </div>
                                </v-tooltip>
                            </span>
                        </div>
                        <v-spacer></v-spacer>
                        <span class="text-color-black arabic-cardbalance mr-lg-16 mr-md-8 mr-3"
                              style="font-size: 11px;font-weight: 800;">
                            <span v-if="selectedLanguage == 'en'">
                                {{ $t(cardDetails.previousBalance.cur) }}
                            </span>
                            {{ cardDetails.previousBalance.amt | VMask(currencyMask) }}
                            <span v-if="selectedLanguage == 'ar'">
                                {{ $t(cardDetails.previousBalance.cur) }}
                            </span>
                            <span v-if="cardDetails.previousBalance.cr">CR</span>
                        </span>
                    </div>

                    <div class="d-flex flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column">
                        <div>
                            <span class="text-black ar-fontsize fontsize">{{ $t('Total Recent Payments') }}</span>
                            <span>
                                <v-tooltip color="primary"
                                           top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img v-bind="attrs"
                                             v-on="on"
                                             src="@/assets/images/icons/iota-circle.png"
                                             alt=""
                                             class="mx-1"
                                             style="width: 10px;">
                                    </template>
                                    <div style="max-width: 200px;padding: 6px;">
                                        <span>{{ $t(`RecentPaymentsInfo`) }}</span>
                                    </div>
                                </v-tooltip>

                            </span>
                        </div>
                        <v-spacer></v-spacer><span class="text-color-black arabic-cardbalance mr-lg-16 mr-md-8 mr-3"
                              style="font-size: 11px;font-weight: 800;">
                            <span v-if="selectedLanguage == 'en'">
                                {{ $t(cardDetails.totalRecentPayments.cur) }}
                            </span>
                            {{ cardDetails.totalRecentPayments.amt | VMask(currencyMask) }}
                            <span v-if="selectedLanguage == 'ar'">
                                {{ $t(cardDetails.totalRecentPayments.cur) }}
                            </span>
                        </span>
                    </div>

                    <div class="d-flex flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column">
                        <div>
                            <span class="text-black ar-fontsize fontsize">{{ $t('Total Recent Transactions') }}</span>
                            <span>
                                <v-tooltip color="primary"
                                           top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img v-bind="attrs"
                                             v-on="on"
                                             src="@/assets/images/icons/iota-circle.png"
                                             alt=""
                                             class="mx-1"
                                             style="width: 10px;">
                                    </template>
                                    <div style="max-width: 200px;padding: 6px;">
                                        <span>{{ $t(`RecentTransactionsInfo`) }}</span>
                                    </div>
                                </v-tooltip>

                            </span>
                        </div>
                        <v-spacer></v-spacer><span class="text-color-black arabic-cardbalance mr-lg-16 mr-md-8 mr-3"
                              style="font-size: 11px;font-weight: 800;">
                            <span v-if="selectedLanguage == 'en'">
                                {{ $t(cardDetails.totalRecentsTransaction.cur) }}
                            </span>
                            {{ cardDetails.totalRecentsTransaction.amt | VMask(currencyMask) }}
                            <span v-if="selectedLanguage == 'ar'">
                                {{ $t(cardDetails.totalRecentsTransaction.cur) }}
                            </span>
                        </span>
                    </div>
                </v-col>
                <v-col cols="12"
                       lg="2"
                       md="12"
                       sm="12"
                       class="balance balance-new">
                    <v-btn @click="onDetails"
                           color="primary"
                           class="px-3">
                        {{ $t('View Details') }}
                    </v-btn>
                </v-col>
            </v-row>




        </v-card-text>
    </v-card>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import store from '@/store';
const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {
    props: {
        cardDetails: {
            default: '',
            type: Object
        },
    },
    data: () => ({
        currencyMask,
        selectedLanguage: 'en'
    }),
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload
                // console.log(this.selectedLanguage);
            }
        })
    },
    mounted()
    {

        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }
    },
    methods: {
        onDetails()
        {
            this.$emit('details')
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

// @media screen and (max-width: 1264px) {
.balance {
    padding: 12px 0px 12px 8px !important;
}

@media (max-width: 1280px) {

    .balance-new {
        padding: 12px 0px 12px 2px !important;
    }
}

.fontsize {
    font-size: 11px;
}

@media (max-width: 780px) {
    .balance {
        padding: 12px !important;
        text-align: center;
    }

    .arabic-cardbalance {
        margin-left: 5px !important;
    }
    .balance-space{
        margin-top: -24px;
    }
}
</style>
