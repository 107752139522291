let productCodeArray = [

    // productCodeHashMap.put("SM1ACC", "CB"); //todo same ?
    // productCodeHashMap.put("SSBACC", "AS"); //todo same ?
    // productCodeHashMap.put("UPRACR", "EG");
    // productCodeHashMap.put("UPRACR", "EP");
    //for default
    // productCodeHashMap.put("MARACC", "NMC");

    { old: "SSBACC", new: "RB" },
    { old: "SRGACC", new: "RG" },
    // { old: "SM1ACC", new: "CB" },
    { old: "SCGACC", new: "CG" },
    { old: "SSPACC", new: "SP" },
    { old: "UPCACR", new: "UP" },
    { old: "SSGACC", new: "SG" },
    { old: "UUGACC", new: "UG" },
    { old: "SSRACC", new: "SR" },
    { old: "UURACC", new: "UR" },
    { old: "UUSACC", new: "US" },
    { old: "SBSACC", new: "BS" },
    { old: "UUCACC", new: "UC" },
    { old: "SBCACC", new: "BC" },
    { old: "SPSACC", new: "PS" },
    { old: "UPRACR", new: "PU" },
    // { old: "SSBACC", new: "AS" },
    { old: "UCEACC", new: "CE" },
    { old: "SMSACC", new: "MS" },
    { old: "UMUACC", new: "MU" },
    { old: "STSACC", new: "TS" },
    { old: "UTUACC", new: "TU" },
    // { old: "UPRACR", new: "EG" },
    // { old: "UPRACR", new: "EP" },
    { old: "UA2ACC", new: "A2" },
    { old: "UA1ACC", new: "A1" },
    { old: "SP2ACC", new: "P2" },
    { old: "UP1ACC", new: "P1" },
    { old: "SA4ACC", new: "A4" },
    { old: "UA3ACC", new: "A3" },
    { old: "SBEACC", new: "BE" },
    { old: "SSSACC", new: "SS" },
    { old: "SM1ACC", new: "M1" },
    { old: "SM2ACC", new: "M2" },
    { old: "SGSACC", new: "GS" },
    { old: "USVACC", new: "SV" },
    { old: "SVPACC", new: "VP" },
    { old: "SMGACC", new: "MG" },
    { old: "SBBACC", new: "BB" },
    { old: "SIBACC", new: "IB" },
    { old: "PCUACC", new: "PC" },
    { old: "SVSACC", new: "VS" },
    { old: "SAPACC", new: "AP" },
    { old: "UGHACC", new: "RH" },
    { old: "UC1ACC", new: "C1" },
    { old: "UC2ACC", new: "C2" },
    { old: "SSAACC", new: "SA" },
    { old: "MARACC", new: "MB" },
]

const getNewProductCode = (oldCode) =>
{
    for (let index = 0; index < productCodeArray.length; index++)
    {
        const element = productCodeArray[index];
        if (oldCode == element.old)
        {
            return element.new
        }
    }
}

export default {
    getNewProductCode
}
