<template>
    <v-card class="mt-6 mb-6">
        <v-row>
            <v-col cols="12"
                   lg="3"
                   class="">
                <v-img contain
                :class="m2Class" class="auth-3d-group overflow-hidden"
                       style="height: 33px;"
                       :src="card.image">
                </v-img>
            </v-col>
            <v-col cols="12"
                   lg="5"
                   md="5"
                   sm="12"
                   class="pa-0 supp-card-text">
                <div>
                    <div style="font-size: 10px;"
                         class="text-black font-weight-bold">
                        {{ card.cardMemberName }}
                    </div>
                    <div style="font-size: 9px;">
                        {{ $t('Card ending -') }} {{ card.Last4Digits }}
                    </div>
                </div>

            </v-col>
            <v-col cols="12"
                   lg="4"
                   class="d-flex align-center">
                <div v-if="!isSupplementarySelected"
                     class="text-center text-blue font-weight-bold"
                     style="margin: auto;font-size: 10px;cursor:pointer"
                     @click="manageCard()">
                    {{ $t('Manage Card') }}
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

export default {
    props: {
        cards: {
            default: [],
            type: Array
        },
        card: {
            default: {},
            type: Object
        },
        singleCard: {
            default: false,
            type: Boolean
        },
        isSupplementarySelected: {
            default: false,
            type: Boolean
        },
        selectedLang: {
            default: 'en',
            type: String 
        }
    },
    computed: {
        m2Class()
        {
            if (this.selectedLang === 'ar')
            {
                return 'mr-2';
            } else
            {
                return 'ml-2';
            }
        },
    },

    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLang = localStorage.getItem('language');
        }
    },
    methods: {
        manageCard()
        {
            this.$emit('manage', {
                cards: this.cards,
                isSupplementarySelected: true,
                card: this.card,
                singleCard: this.singleCard
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.manage-btn {
    padding: 9px 0px 10px;
}

.suspended {
    background: rgba(237, 28, 36, 0.15);
    color: #D92D20;
    width: 126px;
    font-size: 10px;
    padding: 2px 0px 2px;
    margin: 3px 0px -1px;
}



.area {
    background-color: white;
}

.bdr-bottom {
    border-bottom: 1px solid lightgray;
}

</style>
;