const SUCCESS = '00000';
const ClientProtocolException = 'clientError';
const RANDOM_ERROR = '';
const REACH_MAX_CAPTCHA_REQUEST = '81037';
const CAPTCHA_ERROR = '81036';
const USER_VALIDATION_ERROR = '81032';
const CAPTCHA_VALUE_NOT_FOUND = '81045';
const OTP_VALIDATION_FAILED = '81034';
const USER_ENROLMENT_VALIDATION_ERROR = '81039';
const ENTITY_ACCESS_VALIDATION_ERROR = '81035';
const USER_ALREADY_REGISTERED_ERROR = '82030';
const USER_WHILE_CREATING_USER = '82031';
const INVALID_LOGIN_REQUEST = '81040';
const TIME_OUT = '401';
const LOGIN_USER_NOT_VALID = '81038';
const USER_PASSWORD_NOT_VALID = '81051';
const USER_PASSWORD_INVALID = '81052';
const ACCOUNT_LOCKED = '81053';
const NO_DATA_FOUND = '70110';
const OTP_SENT = '222222';
const NO_FILE = '107';
const LIMIT = '1';
const CONTACT_METHOD = '2';
const INFORMATION = '3';
const FILE = '4';
const NO_DATA = '20508';
const ERROR_INFORMATION = '80020';
const ERROR_IN_API = "403";
const INVALID_ID = '80030';
const INTERNAL_SERVER_ERROR = '989898';

const msgs = {
    reach_max_captcha_request: 'Reached maximum number of Captcha requests',
    captcha_error: 'Captcha error',
    user_validation_error: 'We are not able to find a matching detail to the information you provided. Please try again or call our Toll-free 8001242229',
    user_enrolment_validation_error: 'Error in validating a user Enrolment status',
    entity_access_validation_error: 'Error in while validating the entity access',
    captcha_value_not_found: 'Captcha value do not match',
    otp_validation_failed: 'You have entered an invalid Activation Code. Please try again',
    user_already_registered_error: 'User already registered in the identity provider',
    user_while_creating_user: 'Error occurred in while creating the user in the Identity provider',
    invalid_login_request: 'Something went wrong. Please try again.',
    time_out: 'Your session has timed out. Please try again',
    login_user_not_valid: 'User not valid',
    user_password_not_valid: 'The username or password is incorrect',
    USER_PASSWORD_INVALID: 'The username or password is incorrect',
    no_data_found: 'No data found',
    something_went_wrong: 'Something went wrong. Please try again',
    authMessage: 'Authentication code has been sent through SMS',
    account_locked_message: 'User Account is Locked',
    no_file_message: 'The file you are trying to download is not yet available. Please try again later.',
    no_limit: 'Please set your limit greater then previous limit',
    contact_method: 'Please add contact method',
    information: 'Please add information',
    file: 'Please select a file',
    no_data: 'NO DATA FOUND',
    error_in_api: "Your session has expired please refresh the page and try again.",
    internal_server_error: 'System is down, please try again later.'
}

const msgsAr = {
    reach_max_captcha_request: 'تم الوصول إلى الحد الأقصى لعدد طلبات Captcha',
    captcha_error: 'خطأ في كلمة التحقق',
    user_validation_error: 'لا يمكننا العثور على تفاصيل مطابقة للمعلومات التي قدمتها. يرجى المحاولة مرة أخرى أو الاتصال على الرقم المجاني 8001242229',
    user_enrolment_validation_error: 'خطأ في التحقق من حالة تسجيل المستخدم',
    entity_access_validation_error: 'خطأ في التحقق من حالة تسجيل المستخدم',
    captcha_value_not_found: 'قيمة كلمة التحقق غير متطابقة',
    otp_validation_failed: 'لقد ادخلت الرمز خطأً، حاول مجدداً',
    user_already_registered_error: 'المستخدم مسجل بالفعل في موفر الهوية',
    user_while_creating_user: 'حدث خطأ أثناء إنشاء المستخدم في موفر الهوية',
    invalid_login_request: 'هناك خطأ ما. حاول مرة اخرى.',
    time_out: 'انقضت مهلة جلستك. حاول مرة اخرى',
    login_user_not_valid: 'المستخدم غير صالح',
    no_data_found: 'لاتوجد بيانات',
    user_password_not_valid: 'خطأ في اسم المستخدم أو كلمة السر',
    USER_PASSWORD_INVALID: 'خطأ في اسم المستخدم أو كلمة السر',
    something_went_wrong: 'هناك خطأ ما. حاول مرة اخرى',
    authMessage: 'تم إرسال رمز التحقق عبر الرسائل القصيرة',
    // authMessage: 'رمز التفويض يتم تسليمه عبر الرسائل القصيرة.',
    account_locked_message: 'حساب المستخدم مغلق',
    no_file_message: 'الملف الذي تحاول تنزيله غير متاح حالياً. الرجاء معاودة المحاولة في وقت لاحق.',
    no_limit: 'يرجى تعيين الحد الخاص بك أكبر من الحد السابق',
    contact_method: 'الرجاء إضافة طريقة الاتصال',
    information: 'الرجاء إضافة المعلومات',
    file: 'اختر ملفا، رجاءا',
    no_data: 'لاتوجد بيانات',
    error_in_api: "لقد انتهت جلستك ، يرجى تحديث الصفحة والمحاولة مرة أخرى",
    internal_server_error: 'النظام معطل ، يرجى المحاولة مرة أخرى لاحقًا'

}

const get = (errorCode, errorDescription) =>
{
    let lang = localStorage.getItem('language');
    let errorMessages = msgs;
    if (lang == 'ar')
    {
        errorMessages = msgsAr;
    }
    switch (errorCode)
    {
        case REACH_MAX_CAPTCHA_REQUEST:
            return errorMessages['reach_max_captcha_request'];
        case CAPTCHA_ERROR:
            return errorMessages['captcha_error'];
        case USER_VALIDATION_ERROR:
            return errorMessages['user_validation_error'];
        case USER_ENROLMENT_VALIDATION_ERROR:
            return errorMessages['user_enrolment_validation_error'];
        case ENTITY_ACCESS_VALIDATION_ERROR:
            return errorMessages['entity_access_validation_error'];
        case CAPTCHA_VALUE_NOT_FOUND:
            return errorMessages['captcha_value_not_found'];
        case OTP_VALIDATION_FAILED:
            return errorMessages['otp_validation_failed'];
        case USER_ALREADY_REGISTERED_ERROR:
            return errorMessages['user_already_registered_error'];
        case USER_WHILE_CREATING_USER:
            return errorMessages['user_while_creating_user'];
        case INVALID_LOGIN_REQUEST:
            return errorMessages['invalid_login_request'];
        case TIME_OUT:
            return errorMessages['time_out'];
        case LOGIN_USER_NOT_VALID:
            return errorMessages['login_user_not_valid'];
        case USER_PASSWORD_NOT_VALID:
            return errorMessages['user_password_not_valid'];
        case USER_PASSWORD_INVALID:
            return errorMessages['user_password_not_valid'];

        case NO_DATA_FOUND:
            return errorMessages['no_data_found'];
        case OTP_SENT:
            return errorMessages['authMessage'];
        case ACCOUNT_LOCKED:
            return errorMessages['account_locked_message'];
        case NO_FILE:
            return errorMessages['no_file_message'];
        case LIMIT:
            return errorMessages['no_limit'];
        case CONTACT_METHOD:
            return errorMessages['contact_method'];
        case INFORMATION:
            return errorMessages['information'];
        case FILE:
            return errorMessages['file'];
        case NO_DATA:
            return errorMessages['no_data'];
        case ERROR_INFORMATION:
            return errorMessages['user_validation_error'];
        case INVALID_ID:
            return errorMessages['user_validation_error'];
        case ERROR_IN_API:
            return errorMessages['error_in_api'];
        case INTERNAL_SERVER_ERROR:
            return errorMessages['internal_server_error'];

        default:
            return errorDescription ?? errorMessages['something_went_wrong'];
    }
}
export default {
    get
}
