<template>
    <v-card v-if="offers.length != 0"
            flat
            class="bg-gradient">
        <div class="my-5 balance-card-background"
             v-if="offers.length != 0 && loaded">
            <div class="d-flex align-center">
                <h2 class="my-5"
                    style="font-size: 18px;padding-left: 30px;padding-right: 30px;">
                    {{ $t('Available Offers') }}
                </h2>
                <v-spacer></v-spacer>
                <span @click="goToOffers"
                      class="text-right text-blue font-weight-semibold my-5"
                      style="font-size: 12px; cursor: pointer;padding-left: 30px;padding-right: 30px;width: 130px;">
                    {{ $t('See all') }}
                </span>
            </div>
            <offersCard column="6"
                        :offers="offers"></offersCard>
        </div>

        <div class="my-5 balance-card-background text-center"
             v-if="offers.length == 0">
            <div class="no-offers">

                <img src="@/assets/images/logos/Warning.png"
                     alt="">
                <h2 class="text-color-black mt-1">
                    {{ $t('No offers') }}
                </h2>
            </div>
        </div>
    </v-card>
</template>

<script>
import offersCard from '../test/offersCard.vue';
import offerServices from '@/services/offers-api-services';
import sharedServices from '@/services/shared-services';
import router from '@/router';
import store from '@/store';
import moment from 'moment';


export default {
    components: {

        offersCard
    },
    data: () => ({
        offers: [],
        loaded: true,
        selectedLanguage: 'en',
        heading: 'Available Offers',
        loadedCount: 0,
        unsubscribe: null,
        requestInfo: {
            requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
            requestDate: store.get('requestKeys/requestDate'),
            identityKey: store.get('requestKeys/identityKey'),
            channelId: store.get('requestKeys/channelId'),
            channelKey: store.get('requestKeys/channelKey'),
            identitySeed: store.get('requestKeys/identitySeed'),
            userName: store.get('requestKeys/userName'),
        },
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken') && this.loadedCount == 0)
                {
                    this.loggedOffers();
                    this.loadedCount++;
                }

            }
        })

    },

    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        this.loggedOffers();
    },
    methods: {
        goToOffers()
        {
            router.push('/offers')
            window.scrollTo(0, 0);
        },
        checkSavedOffers()
        {
            store.dispatch('loader/show');
            let data = {
                requestInfo: this.requestInfo,
                clientCode: store.get('requestKeys/ClientCode'),
                reference: 'C',
                language: 'E',
            };

            offerServices.GetSavedoffers(data).then(res =>
            {
                if (res.data.responseInfo.errorCode == '00000')
                {
                    this.offers.forEach(elementTwo =>
                    {
                        res.data.savedOffers.forEach(element =>
                        {
                            if (element.offerSerno == elementTwo.offerSerno)
                            {
                                elementTwo['isSaved'] = true;
                            }
                        });
                    });
                }
                else
                {

                }
                this.loaded = true;
                this.loadedCount = 0;
            });
        },
        async loggedOffers()
        {
            this.offers = [];
            store.dispatch('loader/show');
            await sharedServices.getToken('entity', store.get('requestKeys/userName')).then(tokenRes =>
            {
                store.set('requestKeys/channelKey', tokenRes.data.results.channelKey);
                store.set('requestKeys/identityKey', tokenRes.data.results.identityKey);
                store.set('requestKeys/requestDate', tokenRes.data.results.requestDate);
            });

            this.loaded = false;
            let data = {
                requestInfo: this.requestInfo,
                clientCode: store.get('requestKeys/ClientCode'),
                country: "",
                city: "",
                merchant: "",
                category: 0,
                language: localStorage.getItem('language') == 'en' ? 'E' : 'A',
            };

            offerServices.GetLoggedoffers(data).then(res =>
            {
                if (res.data.responseInfo.errorCode == '00000')
                {
                    this.offers = [];
                    let allOffers = res.data.listOffers
                    allOffers.forEach((element, index) =>
                    {
                        element['image'] = 'https://online.americanexpress.com.sa/smartphoneapi/OffersV1/OfferImages/' + element.offerImage;

                        if (element.offerEndDate)
                        {
                            let dates = element.offerEndDate.split('/');

                            let newDate = moment(dates[1] + ' ' + dates[0] + ' ' + dates[2]).format('DD/MMM/YYYY');

                            element['expiryDate'] = newDate;
                            element.offerEndDate = newDate;
                        }


                        if (element.shortDescription.length > 35)
                        {
                            element.shortDescription = (element.shortDescription).substring(0, 35) + '...';
                        }
                        element['isSaved'] = false;


                        if (index < 6)
                        {
                            this.offers.push(element);
                        }
                    });
                    // this.offers = allOffers;

                    this.checkSavedOffers();
                }
                else
                {
                    this.offers = [];
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';


.no-offers {
    width: 31vw;
    margin: 0 auto;
    padding-top: 75px;
    padding-bottom: 75px;
}

@media screen and (max-width: 808px) {

    .no-offers {
        width: 70vw;
        margin: 0 auto;
        padding-top: 75px;
        padding-bottom: 75px;
    }

}


.text-color-black {
    color: #00175A !important;
    margin-bottom: 0px;
    margin-top: 20px;
}

.offers-btn {
    color: #006FCF;
    border: 1px solid #006FCF;
    width: 202px;
    margin: 0 auto;
    padding: 10px 0px;
    border-radius: 12px;
    margin-top: 20px;
    cursor: pointer;
}
</style>
