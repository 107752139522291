<template>
    <v-card style="border-radius: 5px;"
            v-if="!hideTotal">
        <v-card-text class="ar-membership en-membership">
            <v-row class="align-center">
                <v-col cols="12"
                       lg="9"
                       md="12"
                       sm="12"
                       v-if="card.showMembershipPoints">
                    <p class="text-xl font-weight-semibold py-2 px-9 text-rewards"
                       style="margin-bottom: 0;color: #FFFFFF;font-size: 15px !important;">
                        {{ $t('Membership Rewards Point') }}
                    </p>
                </v-col>
                <v-col cols="12"
                       lg="3"
                       md="12"
                       sm="12"
                       v-if="card.showMembershipPoints">
                    <div class="text-2xl font-weight-regular text-center py-2 reward-point">
                        <span style="color: #FFFFFF;font-size: 18px !important;">
                            {{ rewards | VMask(currencyMask) }}
                        </span>
                    </div>
                </v-col>

                <v-col cols="12"
                       lg="12"
                       md="12"
                       sm="12"
                       v-if="!card.showMembershipPoints">
                    <div class="text-md font-weight-regular text-center py-2 d-flex align-center justify-center"
                         style="min-height: 58px;">
                        <span style="color: #FFFFFF;font-size: 14px !important;">
                            {{ $t(getPoints()) }}
                        </span>
                    </div>
                </v-col>
            </v-row>
            <!-- <v-row class="align-center">
                <v-col cols="12"
                       lg="5"
                       md="12"
                       sm="12">
                    <p class="text-xl font-weight-semibold py-2 px-9 text-rewards"
                       style="margin-bottom: 0;color: #FFFFFF;font-size: 15px !important;">
                        {{ $t('Membership Rewards Point') }}
                    </p>
                </v-col>
                <v-col cols="12"
                       lg="7"
                       md="12"
                       sm="12">
                    <div v-if="card.showMembershipPoints"
                         class="text-xl font-weight-regular member-aligin memebership-number-right py-2 reward-point">
                        <span style="color: #FFFFFF;font-size: 18px !important;">
                            {{ rewards | VMask(currencyMask) }}
                        </span>
                    </div>
                    <div v-if="!card.showMembershipPoints"
                         class="text-md font-weight-regular member-aligin memebership-text-right py-2 ">
                        <span style="color: #FFFFFF;font-size: 14px !important;">
                            {{ $t(getPoints()) }}
                        </span>
                    </div>
                </v-col>
            </v-row> -->
        </v-card-text>
        <!-- </v-img> -->
    </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline




import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {

    props: {
        rewards: {
            default: '0',
            type: String
        },
        refresh: {
            default: 0,
            type: Number
        },
        card: {
            default: {},
            type: Object
        }
    },
    data: () => ({
        currencyMask,
        cardText: false,
        hideTotal: false,
    }),
    watch: {
        refresh: function (newVal, oldVal)
        {
            if (this.rewards)
            {
                this.hideTotal = false;
            }
        }
    },
    methods: {
        getPoints()
        {
            if (this.card)
            {
                if (this.card?.ProductCode == 'MB')
                {
                    // The Marriott Bonvoy American Express Credit Card
                    this.cardText = true;
                    this.hideTotal = false;
                    return 'merriott_bonvoy_membership_message';
                }
                else if (this.card?.ProductCode == 'SV')
                {
                    // The AlFursan American Express Gold Credit Card
                    this.cardText = true;
                    this.hideTotal = false;
                    return 'alfursan_membership_message';
                } else
                {
                    this.cardText = false;
                    this.hideTotal = true;
                    return '';
                }
            }
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.en-membership {
    background-image: linear-gradient(to right, #03337f, #03337f, #03337f, #156bb7);
    padding: 1px !important;
}

@media (max-width: 500px) {
    .text-rewards {
        text-align: center;
    }

    .reward-point {
        margin-top: -20px;
    }

    .member-aligin {
        text-align: center !important;
    }
}
</style>
